// import { html } from 'lit';

const isEmpty = (str) => !/\S+/.test(str);

const getQueryParam = (param) => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  return params.get(param) || '';
};

const env2Postfix = () =>
  ({
    local: '-local',
    sbx: '-s',
    dev: '-d',
    tst: '-q',
    qa: '-q',
    uat: '-u',
    prd: '',
  }[process.env.ENV] || '');

const getPrimaryDomainName = () =>
  window.location.hostname.substring(window.location.hostname.lastIndexOf('.', window.location.hostname.lastIndexOf('.') - 1) + 1);

const getDynamicOLUrl = () => `https://gk-api${env2Postfix()}.${getPrimaryDomainName()}`;
const getDynamicWSAuthUrl = () => `https://wsauth${env2Postfix()}.${getPrimaryDomainName()}`;

const isProdEnv = () => process.env.ENV === 'prd';

// const sharedStylesHtml = html`
//     <link
//       rel="stylesheet"
//       href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
//       integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
//       crossorigin="anonymous"
//     />
// `;

const paramToQueryString = (loginParams) => {
  const paramStr =
    !!loginParams &&
    Object.keys(loginParams)
      .map((key) => `${key}=${loginParams[key]}`)
      .join('&');
  return paramStr ? `?${paramStr}` : '';
};

export {
  env2Postfix,
  getQueryParam,
  isEmpty,
  paramToQueryString,
  getPrimaryDomainName,
  getDynamicOLUrl,
  getDynamicWSAuthUrl,
  isProdEnv,
};
